import { AddGroup } from 'static/Icons/AddGroup';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { EllipseGreen } from 'static/Icons/EllipseGreen';

import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react';

import {
    Badge,
    CategoriesContainer,
    CategoryItem,
    ChildWrapper,
    CloseIconWrapper,
    IconWrapper,
    ExpandIcon,
    Service,
    ServiceWrapper,
    CategoriesWrapper,
    MuiPopover,
    CategoriesBox,
} from './styles';

type TItem = {
    id: string;
    categories: any[];
    children: any[];
    category_ids: any[];
    description: string;
    is_active: boolean;
    is_removed: boolean;
    is_root: boolean;
    name: string;
    parents: unknown;
    service_types: any[];
    created_at: string;
    updated_at: string;
};

type Props = {
    item: TItem;
};

export const ServiceItem: FC<Props> = ({ item }) => {
    const [isOpen, setOpen] = useState(false);
    const [openCategories, setOpenCategories] = useState<any>(false);

    const isCategoriesShouldOpening = item.categories?.length > 2;
    const filteredCategories = item.categories?.filter((item, idx) => idx > 1 && item);

    return (
        <div>
            <Service>
                <ServiceWrapper>
                    {item?.children?.length ? (
                        <ExpandIcon
                            isOpen={isOpen}
                            onClick={() => setOpen((prev) => !prev)}
                        />
                    ) : null}
                    <EllipseGreen status={item.is_active} />
                    <Typography sx={{ fontSize: '14px' }}>{item.name}</Typography>
                </ServiceWrapper>
                <CategoriesContainer>
                    <CategoryItem>{item?.categories?.[0]?.name}</CategoryItem>
                    <CategoriesBox
                        isActive={openCategories}
                        onClick={(e) => setOpenCategories(e.currentTarget)}
                    >
                        <span>{item?.categories?.[1]?.name}</span>
                        {isCategoriesShouldOpening ? (
                            <Badge isActive={openCategories}>{filteredCategories?.length}</Badge>
                        ) : null}
                    </CategoriesBox>
                    {isCategoriesShouldOpening ? (
                        <MuiPopover
                            open={openCategories}
                            anchorEl={openCategories}
                            onClose={() => setOpenCategories(false)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Box>
                                <CloseIconWrapper onClick={() => setOpenCategories(false)}>
                                    <CloseIcon />
                                </CloseIconWrapper>
                                <div style={{ color: '#BCBFD1' }}>Категории техники</div>
                                <CategoriesWrapper>
                                    {filteredCategories?.map((item) => {
                                        return <CategoryItem key={item.id}>{item.name}</CategoryItem>;
                                    })}
                                </CategoriesWrapper>
                            </Box>
                        </MuiPopover>
                    ) : null}
                </CategoriesContainer>
                <IconWrapper>
                    <AddGroup />
                </IconWrapper>
            </Service>
            {item?.children?.length
                ? item.children.map((item: any) => {
                      return (
                          <ChildWrapper
                              isOpen={isOpen}
                              key={item.id}
                          >
                              <ServiceItem item={item} />
                          </ChildWrapper>
                      );
                  })
                : null}
        </div>
    );
};
