import { servicesSelector } from 'pages/services/store/selectors';
import { CloseIconWrapper } from 'pages/services/styles';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { useAppDispatch } from 'store/hooks';

import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import { ButtonsWrapper, CheckboxWrapper } from './styles';

type Props = {
    isOpen: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
    setChildServices: (s: any) => void;
};

export const DeleteService: FC<Props> = ({ isOpen, onClose, setChildServices }) => {
    const dispatch = useAppDispatch();

    const chooseDelete = useSelector(servicesSelector).chooseForDelete;
    return (
        <Dialog
            maxWidth="xs"
            fullWidth
            open={isOpen}
            onClose={() => onClose(false)}
        >
            <DialogTitle sx={{ padding: '30px 30px 20px' }}>
                <Box>
                    <CloseIconWrapper onClick={() => onClose(false)}>
                        <CloseIcon />
                    </CloseIconWrapper>
                    <Typography variant="h5">Подтвердить действие</Typography>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: '37px 30px' }}>
                <Typography>Вы уверены, что хотите удалить данную услугу? Она больше не будет доступна.</Typography>
                <CheckboxWrapper>
                    <Checkbox sx={{ padding: 0 }} />
                    <Typography>Удалить все дочерние услуги</Typography>
                </CheckboxWrapper>
                <ButtonsWrapper>
                    <Button
                        onClick={() => {
                            setChildServices((prev: any) => prev.filter((item: any) => item.title !== chooseDelete));
                            onClose(false);
                            dispatch(chooseDelete(null));
                        }}
                        variant="contained"
                    >
                        Удалить
                    </Button>
                    <Button
                        onClick={() => onClose(false)}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                </ButtonsWrapper>
            </DialogContent>
        </Dialog>
    );
};
