import { styled, TextField } from '@mui/material';

export const ButtonsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
});

export const TextInput = styled(TextField)({
    width: '230px',
});
