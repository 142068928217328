export const mockServices = [
    {
        id: 1,
        title: 'Установка сплит-системы',
        isInclude: true,
    },
    {
        id: 2,
        title: 'Замена корпусных элементов',
        isInclude: false,
    },
];
