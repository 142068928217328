export const mockSpecifications = [
    {
        id: 1,
        name: 'Какое-то название 1',
        slug: 'Какое-то название 1',
        units: 'кг',
        value: '0-59',
    },
    {
        id: 2,
        name: 'Какое-то название 2',
        slug: 'Какое-то название 2',
        units: 'см',
        value: 'Строка',
    },
    {
        id: 3,
        name: 'Какое-то название 3',
        slug: 'Какое-то название 3',
        units: '-',
        value: [
            { type: 0, title: 'Значение 1' },
            { type: 0, title: 'Значение 2' },
            { type: 0, title: 'Значение 3' },
        ],
    },
    {
        id: 4,
        name: 'Какое-то название 4',
        slug: 'Какое-то название 4',
        units: '-',
        value: '100',
    },
    {
        id: 5,
        name: 'Какое-то название 5',
        slug: 'Какое-то название 5',
        units: '-',
        value: [
            { type: 1, title: 'Значение 1' },
            { type: 1, title: 'Значение 2' },
        ],
    },
];
