import { chooseDelete } from 'pages/services/store';
import { Cancel } from 'static/Icons/Cancel';
import { Confirm } from 'static/Icons/Confirm';
import { DeleteBasket } from 'static/Icons/DeleteBasket';
import { useAppDispatch } from 'store/hooks';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';

import { DeleteService } from './modals/delete-service';
import { ButtonsContainer, ButtonsWrapper, CheckBox, CheckTitle, MuiTextField, SubTitle } from './styles';

import { FootNote } from '../../footnote';
import { mockServices } from '../mock';
import { Cell, Field, HeadCell, MuiAutocomplete, Asterisk, FieldContainer, PlusButton } from '../styles';

export const Basics = () => {
    const dispatch = useAppDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [childServices, setChildServices] = useState<any[]>(mockServices);
    const [addChildService, setAddService] = useState<any>();
    const [isVisible, setVisible] = useState(false);

    const handleConfirm = () => {
        setChildServices((prev) => [...prev, addChildService]);
        setAddService({});
        setVisible(false);
    };

    const handleCancel = () => {
        setAddService({});
        setVisible(false);
    };

    const handleAddTitleNewChild = (option: string) => {
        setAddService({ title: option, isInclude: false });
    };
    const handleChangeInclude = (checkedValue: boolean) => {
        setAddService((prev: any) => {
            return { ...prev, isInclude: checkedValue };
        });
    };
    const handleDelete = (title: any) => {
        dispatch(chooseDelete(title));
    };

    return (
        <Box>
            <FieldContainer>
                <div>
                    <Field>
                        <SubTitle>
                            Родитель<Asterisk>*</Asterisk>
                        </SubTitle>
                        <MuiAutocomplete
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={['Папа', 'Мама']}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Выбрать"
                                />
                            )}
                        />
                    </Field>
                    <Field sx={{ marginTop: '10px' }}>
                        <SubTitle />
                        <CheckBox />
                        <CheckTitle>Нет родителя</CheckTitle>
                    </Field>
                </div>
                <Field>
                    <SubTitle>
                        Наименование<Asterisk>*</Asterisk>
                    </SubTitle>
                    <MuiTextField size="small" />
                </Field>
                <Field>
                    <SubTitle>
                        Описание<Asterisk>*</Asterisk>
                    </SubTitle>
                    <MuiTextField
                        multiline
                        placeholder="Какое-то описание"
                        minRows={4}
                    />
                </Field>
                <Field>
                    <SubTitle>Категории</SubTitle>
                    <MuiAutocomplete
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={['Все категории', 'Категория1', 'Категория2', 'Категория3']}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Field>
                <Field>
                    <SubTitle>Типы услуг</SubTitle>
                    <MuiAutocomplete
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={['Тип услуг1', 'Тип услуг2', 'Тип услуг3', 'Тип услуг4']}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Типы услуг"
                            />
                        )}
                    />
                </Field>
                <Field sx={{ marginTop: '10px' }}>
                    <SubTitle>Активная</SubTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '555px' }}>
                        <CheckBox />
                        <FootNote />
                    </div>
                </Field>
            </FieldContainer>
            <Box sx={{ marginTop: '28px' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Дочерние услуги</Typography>
            </Box>

            <Table>
                <TableHead>
                    <TableRow>
                        <HeadCell sx={{ width: '450px' }}>НАИМЕНОВАНИЕ УСЛУГИ</HeadCell>
                        <HeadCell sx={{ width: '450px' }}>ВКЛЮЧАТЬ В СТОИМОСТЬ</HeadCell>
                        <HeadCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {childServices.map((item) => {
                        return (
                            <TableRow key={item.id}>
                                <Cell>{item.title}</Cell>
                                <Cell>
                                    <Checkbox checked={item.isInclude} />
                                </Cell>
                                <Cell>
                                    <Button
                                        onClick={() => {
                                            handleDelete(item.title);
                                            setIsOpen(true);
                                        }}
                                    >
                                        <DeleteBasket />
                                    </Button>
                                </Cell>
                            </TableRow>
                        );
                    })}
                    <DeleteService
                        onClose={setIsOpen}
                        setChildServices={setChildServices}
                        isOpen={isOpen}
                    />
                    {isVisible ? (
                        <TableRow>
                            <Cell>
                                <Autocomplete
                                    size="small"
                                    sx={{ width: '260px' }}
                                    popupIcon={<ExpandMoreIcon />}
                                    options={['1', '2', '3', '4']}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выберите услугу"
                                        />
                                    )}
                                    onChange={(_, value) => handleAddTitleNewChild(value as string)}
                                />
                            </Cell>
                            <Cell>
                                <Checkbox onChange={(e) => handleChangeInclude(e.target.checked)} />
                            </Cell>
                            <Cell>
                                <ButtonsContainer>
                                    <div onClick={handleConfirm}>
                                        <Confirm />
                                    </div>
                                    <div onClick={handleCancel}>
                                        <Cancel />
                                    </div>
                                </ButtonsContainer>
                            </Cell>
                        </TableRow>
                    ) : (
                        <TableRow>
                            <PlusButton
                                onClick={() => setVisible(true)}
                                startIcon={<AddIcon />}
                            >
                                Добавить услугу
                            </PlusButton>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <ButtonsWrapper>
                <Button color="error">Удалить</Button>
                <Button variant="contained">Создать</Button>
            </ButtonsWrapper>
        </Box>
    );
};
