import { Edit } from 'static/Icons/Edit';

import Add from '@mui/icons-material/Add';
import { Table as MuiTable, TableBody, TableHead, TableRow, TextField } from '@mui/material';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { mockTable } from './mock';
import { AddInPriceLists } from './modals/add-pricelist';
import { PriceAndCommission } from './modals/price-commission';
import { CellInputProps, EditIconWrapper } from './styles';

import { Cell, HeadCell, PlusButton } from '../styles';

export const Table = () => {
    const [isOpenModal, setOpenModal] = useState(false);
    const [isOpenCommission, setOpenCommission] = useState(false);
    const [isOpenCost, setOpenCost] = useState(false);

    return (
        <>
            <MuiTable sx={{ marginTop: '10px' }}>
                <TableHead>
                    <HeadCell>ПРАЙС-ЛИСТ</HeadCell>
                    <HeadCell>КОМПЛЕКС УСЛУГ</HeadCell>
                    <HeadCell>
                        СТОИМОСТЬ КОМПЛЕКСА УСЛУГ
                        <EditIconWrapper onClick={() => setOpenCost(true)}>
                            <Edit />
                        </EditIconWrapper>
                    </HeadCell>
                    <HeadCell>
                        КОМИССИЯ МАСТЕРА
                        <EditIconWrapper onClick={() => setOpenCommission(true)}>
                            <Edit />
                        </EditIconWrapper>
                    </HeadCell>
                </TableHead>
                <TableBody>
                    {mockTable.map((item) => {
                        return (
                            <TableRow key={item.id}>
                                <Cell>{item.pricelist}</Cell>
                                <Cell>{item.rangeServices}</Cell>
                                <Cell>
                                    <NumericFormat
                                        inputProps={CellInputProps}
                                        suffix=" ₽"
                                        customInput={TextField}
                                    />
                                </Cell>
                                <Cell>
                                    <NumericFormat
                                        inputProps={CellInputProps}
                                        suffix=" ₽"
                                        customInput={TextField}
                                    />
                                </Cell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <PlusButton
                    startIcon={<Add />}
                    onClick={() => setOpenModal(true)}
                >
                    Добавить в прайс-лист
                </PlusButton>
            </MuiTable>
            <AddInPriceLists
                setOpenModal={setOpenModal}
                isOpenModal={isOpenModal}
            />
            <PriceAndCommission
                title="Комиссия мастера для всех прайс-листов"
                isOpen={isOpenCommission}
                setOpen={setOpenCommission}
            />
            <PriceAndCommission
                title="Стоимость комплекса услуг для всех прайс-листов"
                isOpen={isOpenCost}
                setOpen={setOpenCost}
            />
        </>
    );
};
