import { Drawer, Paper, styled } from '@mui/material';

export const MainPaper = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginTop: '16px',
    padding: '18px',
    borderRadius: '8px',
    overflow: 'auto',
    height: '100%',
});

export const MuiDrawer = styled(Drawer)({
    '.MuiDrawer-paper': {
        marginTop: '80px',
        height: 'calc(100vh - 80px)',
        borderRadius: '8px 0 0 8px',
    },
    '.css-1160xiw-MuiPaper-root-MuiDrawer-paper': {
        boxShadow: '-13px 0px 13px -10px rgba(0,0,0,0.25)',
    },
});

export const PageWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

export const CloseIconWrapper = styled('div')({
    position: 'absolute',
    right: 10,
    top: 0,
    cursor: 'pointer',
});
