import { CloseIcon } from 'static/Icons/CloseIcon';

import { FC, useState } from 'react';

import { Basics } from './basics';
import { Integrations } from './integrations';
import { PriceLists } from './price-lists';
import { Specifications } from './specifications';
import { CloseIconWrapper, Header, ModalWrapper, MuiDivider, MuiTab, MuiTabs } from './styles';

type Props = {
    setOpen: (isOpen: boolean) => void;
};

export const CreateService: FC<Props> = ({ setOpen }) => {
    const [value, setValue] = useState<any>(0);

    const handleChangeTabs = (e: any, value: any) => {
        setValue(value);
    };

    const whatToRender = (value: any) => {
        switch (value) {
            case 0:
                return <Basics />;
            case 1:
                return <PriceLists />;
            case 2:
                return <Specifications />;
            case 3:
                return <Integrations />;
        }
    };

    return (
        <ModalWrapper>
            <Header>
                <MuiTabs
                    onChange={handleChangeTabs}
                    value={value}
                >
                    <MuiTab label="Основное" />
                    <MuiTab label="Прайс-листы" />
                    <MuiTab label="Спецификации" />
                    <MuiTab label="Внешние интеграции" />
                </MuiTabs>
                <CloseIconWrapper onClick={() => setOpen(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
            </Header>
            <MuiDivider sx={{ color: 'rgba(0, 0, 0, 0.08)', marginBottom: '20px' }} />
            {whatToRender(value)}
        </ModalWrapper>
    );
};
