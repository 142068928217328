import { ActiveRadioChecked } from 'static/Icons/ActiveRadioChecked';
import { Cancel } from 'static/Icons/Cancel';
import { Confirm } from 'static/Icons/Confirm';
import { DeleteBasket } from 'static/Icons/DeleteBasket';
import { InactiveRadioChecked } from 'static/Icons/InactiveRadioChecked';

import Add from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Button, Table, TableBody, TableHead, TableRow, TextField } from '@mui/material';
import { useState } from 'react';

import { mockSpecifications } from './mock';
import { ButtonsContainer, CellInputProps, CheckBox, CheckItem } from './styles';

import { Cell, CellCenter, HeadCell, HeadCellCenter, PlusButton } from '../styles';

export const Specifications = () => {
    const [isVisible, setVisible] = useState(false);
    const [specifications, setSpecifications] = useState<any[]>(mockSpecifications);

    const [addSpecifications, setAddSpecifications] = useState<any>();

    const handleConfirm = () => {
        setSpecifications((prev) => [...prev, addSpecifications]);
        setAddSpecifications({});
        setVisible(false);
    };

    const handleCancel = () => {
        setAddSpecifications({});
        setVisible(false);
    };

    const handleAddName = (value: string) => {
        setAddSpecifications((prev: any) => {
            return { ...prev, name: value };
        });
    };

    const handleAddSlug = (value: string) => {
        setAddSpecifications((prev: any) => {
            return { ...prev, slug: value };
        });
    };

    const handleAddUnits = (value: string) => {
        setAddSpecifications((prev: any) => {
            return { ...prev, units: value };
        });
    };

    const handleAddValue = (value: string) => {
        setAddSpecifications((prev: any) => {
            return { ...prev, value: value };
        });
    };

    const handleDeleteSpecification = (id: number) => {
        setSpecifications((prev) => prev.filter((item) => item.id !== id));
    };

    return (
        <Table>
            <TableHead>
                <HeadCell>НАИМЕНОВАНИЕ</HeadCell>
                <HeadCellCenter>SLUG</HeadCellCenter>
                <HeadCellCenter>ЕДИНИЦЫ ИЗМЕРЕНИЯ</HeadCellCenter>
                <HeadCellCenter>ЗНАЧЕНИЕ</HeadCellCenter>
                <HeadCellCenter sx={{ width: '50px' }} />
            </TableHead>
            <TableBody>
                {specifications.map((item) => {
                    return (
                        <TableRow key={item.id}>
                            <Cell>{item.name}</Cell>
                            <CellCenter>{item.slug}</CellCenter>
                            <CellCenter>{item.units}</CellCenter>
                            <CellCenter>
                                {Array.isArray(item.value) ? (
                                    <div>
                                        {item.value.map((item: any) => {
                                            return (
                                                <CheckItem key={item.title}>
                                                    {item.type ? (
                                                        <CheckBox
                                                            icon={<InactiveRadioChecked />}
                                                            checkedIcon={<ActiveRadioChecked />}
                                                        />
                                                    ) : (
                                                        <CheckBox />
                                                    )}
                                                    <span>{item.title}</span>
                                                </CheckItem>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <TextField
                                        value={item.value}
                                        size="small"
                                        inputProps={CellInputProps}
                                    />
                                )}
                            </CellCenter>
                            <Cell>
                                <Button onClick={() => handleDeleteSpecification(item.id)}>
                                    <DeleteBasket />
                                </Button>
                            </Cell>
                        </TableRow>
                    );
                })}
                {isVisible ? (
                    <TableRow>
                        <Cell>
                            <Autocomplete
                                size="small"
                                sx={{ width: '260px' }}
                                popupIcon={<ExpandMoreIcon />}
                                options={['1', '2', '3', '4']}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Выберите услугу"
                                    />
                                )}
                                onChange={(_, value) => handleAddName(value as string)}
                            />
                        </Cell>
                        <Cell>
                            <TextField
                                onChange={(e) => handleAddSlug(e.target.value)}
                                size="small"
                            />
                        </Cell>
                        <Cell>
                            <TextField
                                onChange={(e) => handleAddUnits(e.target.value)}
                                size="small"
                            />
                        </Cell>
                        <Cell>
                            <TextField
                                onChange={(e) => handleAddValue(e.target.value)}
                                size="small"
                            />
                        </Cell>
                        <Cell>
                            <ButtonsContainer>
                                <div onClick={handleConfirm}>
                                    <Confirm />
                                </div>
                                <div onClick={handleCancel}>
                                    <Cancel />
                                </div>
                            </ButtonsContainer>
                        </Cell>
                    </TableRow>
                ) : (
                    <TableRow>
                        <PlusButton
                            onClick={() => setVisible(true)}
                            startIcon={<Add />}
                        >
                            Добавить спецификацию
                        </PlusButton>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};
