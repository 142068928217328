import { workersApi } from 'api';
import { FilterIn } from 'features/filter-in';
import CategoriesRatiosFilter from 'modules/CategoriesRatiosFilter';
import { FootNote } from 'pages/services/components/footnote';
import { CloseIconWrapper } from 'pages/services/styles';
import { ActiveRadioChecked } from 'static/Icons/ActiveRadioChecked';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { InactiveRadioChecked } from 'static/Icons/InactiveRadioChecked';

import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';

import {
    ButtonsWrapper,
    CheckBox,
    CheckBoxContainer,
    CheckBoxItem,
    CheckLabel,
    ServiceName,
    SmallWrapper,
    SubTitle,
} from './styles';

import { Asterisk, Field, FieldContainer } from '../../../styles';
import { ButtonsContainer } from '../../styles';
import { AddConfirm } from '../add-confirm';

type Props = {
    isOpenModal: boolean;
    setOpenModal: (value: boolean) => void;
};

export const AddInPriceLists: FC<Props> = ({ isOpenModal, setOpenModal }) => {
    const [checkValue, setCheckValue] = useState<string>('default');
    const [isOpen, setOpen] = useState(false);

    const change = () => {};

    const whatToRender = (value: string) => {
        switch (value) {
            case 'addFilters':
                return (
                    <ButtonsContainer>
                        <CategoriesRatiosFilter onChange={() => change()} />
                        <FilterIn
                            title="Партнер"
                            onChange={change}
                            activeIds={[]}
                            fetcher={(params) => workersApi.getWorkersNew({ size: 20, ...params })}
                            cleaner={change}
                        />
                    </ButtonsContainer>
                );
            case 'addInSelected':
                return (
                    <ButtonsContainer>
                        <FilterIn
                            title="Наименование"
                            onChange={change}
                            activeIds={[]}
                            fetcher={(params) => workersApi.getWorkersNew({ size: 20, ...params })}
                            cleaner={change}
                        />
                        <CategoriesRatiosFilter onChange={() => change()} />
                        <FilterIn
                            title="Партнер"
                            onChange={change}
                            activeIds={[]}
                            fetcher={(params) => workersApi.getWorkersNew({ size: 20, ...params })}
                            cleaner={change}
                        />
                    </ButtonsContainer>
                );
        }
    };

    return (
        <Dialog
            open={isOpenModal}
            onClose={() => setOpenModal(false)}
            sx={{
                '.MuiDialog-paper': {
                    minWidth: '992px',
                },
            }}
        >
            <DialogTitle>
                <CloseIconWrapper onClick={() => setOpenModal(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
                <Typography variant="h5">Добавить в прайс-лист</Typography>
            </DialogTitle>
            <DialogContent>
                <CheckBoxContainer>
                    <CheckBoxItem>
                        <CheckBox
                            id="default"
                            icon={<InactiveRadioChecked />}
                            checkedIcon={<ActiveRadioChecked />}
                            onClick={() => setCheckValue('default')}
                            checked={checkValue === 'default'}
                        />
                        <CheckLabel htmlFor="default">Добавить во все прайс-листы</CheckLabel>
                    </CheckBoxItem>
                    <CheckBoxItem>
                        <CheckBox
                            id="addFilters"
                            icon={<InactiveRadioChecked />}
                            checkedIcon={<ActiveRadioChecked />}
                            onClick={() => setCheckValue('addFilters')}
                            checked={checkValue === 'addFilters'}
                        />
                        <CheckLabel htmlFor="addFilters">
                            Добавить во все прайс-листы, соответствующие фильтру
                        </CheckLabel>
                    </CheckBoxItem>
                    <CheckBoxItem>
                        <CheckBox
                            id="addInSelected"
                            icon={<InactiveRadioChecked />}
                            checkedIcon={<ActiveRadioChecked />}
                            onClick={() => setCheckValue('addInSelected')}
                            checked={checkValue === 'addInSelected'}
                        />
                        <CheckLabel htmlFor="addInSelected">Добавить в выбранные</CheckLabel>
                    </CheckBoxItem>
                </CheckBoxContainer>

                {whatToRender(checkValue)}

                <FieldContainer>
                    <Field>
                        <SubTitle>
                            Наименование<Asterisk>*</Asterisk>
                        </SubTitle>
                        <TextField
                            placeholder="Наименование"
                            size="small"
                        />
                    </Field>
                    <Field>
                        <SubTitle>
                            Описание<Asterisk>*</Asterisk>
                        </SubTitle>
                        <TextField
                            placeholder="Описание"
                            size="small"
                            multiline
                        />
                    </Field>
                    <Field>
                        <SubTitle>
                            <SubTitle>Что входит в стоимость</SubTitle>
                            <Box sx={{ color: '#A4A7B8', fontSize: '12px' }}>
                                Невыбранные элементы будут отображаться в блоке «Что не входит в стоимость»
                            </Box>
                        </SubTitle>
                        <SmallWrapper sx={{ flexDirection: 'column' }}>
                            <SmallWrapper>
                                <CheckBox />
                                <ServiceName>Дочерняя услуга 1</ServiceName>
                            </SmallWrapper>
                            <SmallWrapper>
                                <CheckBox />
                                <ServiceName>Дочерняя услуга 3</ServiceName>
                            </SmallWrapper>
                            <SmallWrapper>
                                <CheckBox />
                                <ServiceName>Дочерняя услуга 5</ServiceName>
                            </SmallWrapper>
                        </SmallWrapper>
                    </Field>
                    <Field>
                        <SubTitle>
                            Цена<Asterisk>*</Asterisk>
                        </SubTitle>
                        <TextField size="small" />
                    </Field>
                    <Field>
                        <SubTitle>
                            Комиссия мастера<Asterisk>*</Asterisk>
                        </SubTitle>
                        <TextField size="small" />
                    </Field>
                    <Divider sx={{ margin: '20px 0' }} />
                    <Field>
                        <SubTitle>Доступен в API-интеграции</SubTitle>
                        <CheckBox />
                    </Field>
                    <Field>
                        <SubTitle>Внешний идентификатор</SubTitle>
                        <TextField
                            placeholder="Описание"
                            size="small"
                        />
                    </Field>
                </FieldContainer>
                <ButtonsWrapper>
                    <Button
                        variant="contained"
                        onClick={() => setOpen(true)}
                    >
                        Добавить
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ marginRight: '157px' }}
                    >
                        Отмена
                    </Button>
                    <FootNote />
                </ButtonsWrapper>
                <AddConfirm
                    isOpen={isOpen}
                    setOpen={setOpen}
                />
            </DialogContent>
        </Dialog>
    );
};
