import { Box, Checkbox, styled, Typography } from '@mui/material';

export const CheckBoxContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});

export const CheckBoxItem = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
});

export const SubTitle = styled(Typography)({
    width: '215px',
    fontSize: '14px',
});

export const SmallWrapper = styled(Box)({
    display: 'flex',
    gap: '8px',
});

export const CheckBox = styled(Checkbox)({
    padding: 0,
});

export const ServiceName = styled(Typography)({
    fontSize: '14px',
});

export const ButtonsWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    marginTop: '35px',
});

export const CheckLabel = styled('label')({
    cursor: 'pointer',
});
