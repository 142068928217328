export const mockTable = [
    {
        id: 1,
        pricelist: 'Какое-то название 1',
        rangeServices: 'Какое-то название 1',
        priceRangeServices: '-',
        commission: '-',
    },
    {
        id: 2,
        pricelist: 'Какое-то название 2',
        rangeServices: 'Какое-то название 2',
        priceRangeServices: '-',
        commission: '-',
    },
    {
        id: 3,
        pricelist: 'Какое-то название 3',
        rangeServices: 'Какое-то название 3',
        priceRangeServices: '-',
        commission: '-',
    },
];
