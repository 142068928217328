import { Cancel } from 'static/Icons/Cancel';
import { Confirm } from 'static/Icons/Confirm';
import { DeleteBasket } from 'static/Icons/DeleteBasket';

import Add from '@mui/icons-material/Add';
import { Button, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { useState } from 'react';

import { integrationsMock } from './mock';
import { ButtonsContainer, TextInput } from './styles';

import { CellCenter, HeadCellCenter, PlusButton } from '../styles';

export const Integrations = () => {
    const [isVisible, setVisible] = useState(false);
    const [integrations, setIntegrations] = useState<any[]>(integrationsMock);
    const [addIntegration, setAddIntegration] = useState<any>();

    const handleAddCompany = (value: string) => {
        setAddIntegration((prev: any) => {
            return { ...prev, company: value };
        });
    };

    const handleAddIdentifier = (value: string) => {
        setAddIntegration((prev: any) => {
            return { ...prev, identifier: value };
        });
    };

    const handleConfirm = () => {
        setIntegrations((prev) => [...prev, addIntegration]);
        setAddIntegration({});
        setVisible(false);
    };

    const handleCancel = () => {
        setAddIntegration({});
        setVisible(false);
    };

    const handleDeleteIntegration = (id: number) => {
        setIntegrations((prev) => prev.filter((item) => item.id !== id));
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <HeadCellCenter sx={{ width: '230px' }}>КОМПАНИЯ</HeadCellCenter>
                    <HeadCellCenter>ИДЕНТИФИКАТОР</HeadCellCenter>
                    <HeadCellCenter sx={{ width: '50px' }} />
                </TableRow>
            </TableHead>
            <TableBody>
                {integrations.map((item) => {
                    return (
                        <TableRow key={item.id}>
                            <CellCenter>
                                <TextInput
                                    value={item.company}
                                    size="small"
                                />
                            </CellCenter>
                            <CellCenter>
                                <TextInput
                                    value={item.identifier}
                                    size="small"
                                />
                            </CellCenter>
                            <CellCenter>
                                <Button onClick={() => handleDeleteIntegration(item.id)}>
                                    <DeleteBasket />
                                </Button>
                            </CellCenter>
                        </TableRow>
                    );
                })}
                {isVisible ? (
                    <TableRow>
                        <CellCenter>
                            <TextInput
                                onChange={(e) => handleAddCompany(e.target.value)}
                                size="small"
                            />
                        </CellCenter>
                        <CellCenter>
                            <TextInput
                                onChange={(e) => handleAddIdentifier(e.target.value)}
                                size="small"
                            />
                        </CellCenter>
                        <CellCenter>
                            <ButtonsContainer>
                                <div onClick={handleConfirm}>
                                    <Confirm />
                                </div>
                                <div onClick={handleCancel}>
                                    <Cancel />
                                </div>
                            </ButtonsContainer>
                        </CellCenter>
                    </TableRow>
                ) : null}
                <TableRow>
                    <PlusButton
                        onClick={() => setVisible(true)}
                        startIcon={<Add />}
                    >
                        Добавить
                    </PlusButton>
                </TableRow>
            </TableBody>
        </Table>
    );
};
