import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

export default class ServicesAPI {
    getServices() {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/services`,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }
}
