import { servicesApi } from 'api';
import { createSlice } from 'store/hooks';

import { PayloadAction } from '@reduxjs/toolkit';

import { TState } from './types';

export const initialState: TState = {
    services: [],

    loading: false,
    chooseForDelete: null,
};

const slice = createSlice({
    name: 'services',
    initialState,
    reducers: (create) => ({
        fetchServices: create.asyncThunk(
            async (_, { dispatch }) => {
                const { data } = await servicesApi.getServices();

                dispatch(setServices(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        setServices: create.reducer((state, { payload }: PayloadAction<any>) => {
            state.services = payload;
        }),

        chooseDelete: create.reducer((state, { payload }: PayloadAction<string>) => {
            state.chooseForDelete = payload;
        }),
    }),
});

export const { fetchServices, chooseDelete, setServices } = slice.actions;

export default slice.reducer;
