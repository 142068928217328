import { workersApi } from 'api';
import { FilterIn } from 'features/filter-in';
import CategoriesRatiosFilter from 'modules/CategoriesRatiosFilter';

import { Box } from '@mui/material';

import { ButtonsContainer } from './styles';
import { Table } from './table';

export const PriceLists = () => {
    const change = () => {};
    return (
        <Box>
            <ButtonsContainer>
                <FilterIn
                    title="Наименование"
                    onChange={change}
                    activeIds={[]}
                    fetcher={(params) => workersApi.getWorkersNew({ size: 20, ...params })}
                    cleaner={change}
                />
                <CategoriesRatiosFilter onChange={() => change()} />
                <FilterIn
                    title="Партнер"
                    onChange={change}
                    activeIds={[]}
                    fetcher={(params) => workersApi.getWorkersNew({ size: 20, ...params })}
                    cleaner={change}
                />
            </ButtonsContainer>
            <Table />
        </Box>
    );
};
