import { workersApi } from 'api';
import { FilterIn } from 'features/filter-in';
import MainLayout from 'layouts/MainLayout';
import { useAppDispatch } from 'store/hooks';
import { serviceSelector } from 'store/selectors/servicesSelector';

import { Search } from '@mui/icons-material';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CreateService } from './components/create-service';
import { ServiceItem } from './components/service-item';
import { fetchServices } from './store';
import { MainPaper, MuiDrawer, PageWrapper } from './styles';

export const ServicesPage = () => {
    const dispatch = useAppDispatch();

    const { services } = useSelector(serviceSelector);

    const [isOpen, setOpen] = useState<any>(false);

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log(e);
    };

    const handleChange = () => {};
    const handleClean = () => {};

    useEffect(() => {
        dispatch(fetchServices(dispatch));
    }, []);

    return (
        <MainLayout>
            <PageWrapper>
                <div className="services-page-header">
                    <Typography
                        variant={'h4'}
                        fontWeight={500}
                    >
                        Услуги
                    </Typography>
                </div>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                    <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        <TextField
                            placeholder="Поиск"
                            variant="outlined"
                            size="small"
                            onChange={(e) => handleChangeInput(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FilterIn
                            title="Категория"
                            onChange={handleChange}
                            activeIds={[]}
                            fetcher={(params) => workersApi.getWorkersNew({ size: 20, ...params })}
                            cleaner={handleClean}
                        />
                        <FilterIn
                            title="Тип услуги"
                            onChange={handleChange}
                            activeIds={[]}
                            fetcher={(params) => workersApi.getWorkersNew({ size: 20, ...params })}
                            cleaner={handleClean}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        onClick={(e) => setOpen(e.currentTarget)}
                    >
                        Создать услугу
                    </Button>
                    <MuiDrawer
                        open={Boolean(isOpen)}
                        anchor="right"
                        onClose={() => setOpen(false)}
                        hideBackdrop
                    >
                        <CreateService setOpen={setOpen} />
                    </MuiDrawer>
                </Box>
                <MainPaper>
                    {services.map((item) => (
                        <ServiceItem
                            key={item.id}
                            item={item}
                        />
                    ))}
                </MainPaper>
            </PageWrapper>
        </MainLayout>
    );
};
