export const integrationsMock = [
    {
        id: 1,
        company: 'Какое-то название компании 1',
        identifier: '1111111',
    },
    {
        id: 2,
        company: 'Какое-то название компании2 ',
        identifier: '2222222',
    },
];
